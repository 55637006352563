import React from 'react';
import PCBAimg from '../../../images/Solution/PCBA.jpg';

export default function PCBA() {
    return (
        <div className="bg-gray-100 text-gray-800 min-h-fit flex flex-col items-center justify-center">
            <div className="container mx-auto 
             flex flex-col md:flex-row items-center">
                {/*  Image Section */}
                <div className="w-full md:w-[65%] flex justify-center mb-6 md:mb-0 mr-10">
                    <img src={PCBAimg} alt="EMS Service" className="w-full h-auto rounded shadow-md" />
                </div>

                {/* Text Section */}
                <div className="w-full md:w-[35%">
                    <p className="m-6 text-left">
                        We offer a top-tier range of PCB (Printed Circuit Board) Assembly Services designed with cutting-edge technology to guarantee optimal performance and durability. Our assemblies are compact, making them ideal for use in a variety of electrical devices. With a deep understanding of market demands, we provide our products in different sizes and specifications to meet diverse requirements. Each unit is delivered safely and at competitive market prices.

                        PCB Assembly, also known as PCBA, involves the precise mounting of electronic components onto a PCB, ensuring proper connections for reliable device functionality. Although the assembly process may vary based on complexity and component count, the essential steps remain consistent across projects. Our PCB Manufacturing Service includes key features such as:
                    </p>

                    <h2 className="text-3xl font-semibold mb-4">Key Features:</h2>
                    <ul className="list-disc list-inside mb-6">
                        <li><strong>Guaranteed high quality</strong> </li>
                        <li><strong>Extended durability</strong></li>
                        <li><strong>Exceptional performance</strong> </li>
                        <li><strong>Support for both SMD (Surface-Mount Devices) and Through-Hole components</strong> </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
