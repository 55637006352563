import React from 'react'
import background from '../../images/pcb-bg.png';
const Aboutheads = () => {
  return (
    <div className="relative w-full h-80 overflow-hidden">
    <img 
      src={background} 
      alt="PCB Background" 
      className="w-full h-full object-cover" 
    />
    <h1 className="absolute inset-0 flex items-center justify-center text-4xl font-bold text-white bg-black bg-opacity-50 text-center">
    Inside Swastik Electrotech Automation
    </h1>
  </div>
  )
}

export default Aboutheads