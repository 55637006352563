import React from 'react';
import EMSimg from '../../../images/Solution/WMS.png';

const EMS = () => {
  return (
    <div className="bg-gray-100 text-gray-800 min-h-fit flex flex-col">
      <div className="container mx-auto px-4 py-10 flex flex-col md:flex-row items-center">
        {/* Text Section */}
        <div className="max-w-[60%] mb-10 md:mb-0 md:mr-10">
          <p className="mb-6">
            PCB Design Services saw remarkable growth during the 1980s as the landscape of electronics manufacturing shifted.
            Initially dominated by in-house assembly at Original Equipment Manufacturers (OEMs), the industry began to embrace
            outsourcing for smaller production runs. This evolution allowed smaller companies to benefit from increased
            flexibility and reduced human resource demands. Our PCB design services specialize in leveraging economies of scale
            in PCB manufacturing, procurement of raw materials, and pooling resources and expertise. We also offer value-added
            services such as warranties and repairs, ensuring you receive comprehensive support throughout the manufacturing process.
          </p>

          <h2 className="text-3xl font-semibold mb-4">Key Features:</h2>
          <ul className="list-disc list-inside mb-6">
            <li><strong>Accurate Size:</strong> Precision in the dimensions of the printed circuit boards, ensuring a perfect fit for all electronic components.</li>
            <li><strong>Superior Quality:</strong> High-quality materials and assembly processes that meet industry standards.</li>
            <li><strong>Advanced Variety:</strong> A wide range of services and components to meet various PCB manufacturing needs.</li>
          </ul>

          <h2 className="text-3xl font-semibold mb-4">Our Primary Services:</h2>
          <ul className="list-disc list-inside ">
            <li><strong>PCBM (Printed Circuit Board Manufacturing):</strong> Full-service PCB manufacturing to meet diverse customer needs.</li>
            <li><strong>PCBA (Printed Circuit Board Assembly):</strong> Comprehensive assembly services, including soldering and component placement.</li>
            <li><strong>Component Sourcing:</strong> Efficient and reliable sourcing of high-quality components for electronics manufacturing.</li>
          </ul>
        </div>

        {/* EMS Image Section */}
        <div className="flex justify-center max-w-[40%] mb-10 md:mb-0">
          <img src={EMSimg} alt="EMS Service" className="max-w-full h-auto rounded shadow-md" />
        </div>
      </div>
    </div>
  );
}

export default EMS;
