import React from 'react';
import img1 from '../../images/About-Page.webp';
// import img2 from '../../images/AboutPage-img2.jpg'; 

export default function WhoWe() {
  return (
    <div className="bg-gray-100 py-10 px-6 lg:px-20">
      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between">
        <div className="lg:w-[60%]">
          <h1 className="text-4xl font-bold text-left text-[#150A76] mb-12 relative inline-block">
            Quality-Driven PCB Solutions
            <span
              className="absolute left-0 -bottom-6 w-20 h-1 bg-[#150A76]"
              style={{ content: '""' }}
            ></span>
          </h1>
          <p className="text-lg text-gray-700 mb-4">
            Founded in 2013, <span className="font-semibold text-gray-900">Swastik Electrotech Automation</span> is a leading EMS company specializing in Contract Manufacturing and Services for Printed Circuit Board Assembly (PCBA), LED Circuit Boards, SMD IC Work, and PCB Fabrication. Based in Pune, we cater to a wide range of industries, assembling all types of PCBs tailored to client specifications. Our team of highly skilled professionals operates with cutting-edge technology and state-of-the-art machinery.
          </p>
          <p className="text-lg text-gray-700">
            At <span className="font-semibold text-gray-900">Swastik Electrotech Automation</span>, we place a strong emphasis on delivering high-quality, advanced solutions for diverse applications. Our products are meticulously crafted and undergo rigorous quality checks to ensure reliability. Thanks to our modern infrastructure, advanced facilities, and well-defined processes, we offer dependable products at competitive market prices.
          </p>
        </div>
        <div className="lg:w-[30%]  mt-8 lg:mt-0 lg:ml-10">
          <img src={img1} alt="Swastik Electrotech Automation" className="w-full h-auto rounded-lg shadow-md" />
        </div>
      </div>
    </div>
  );
}
