// src/ContactForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Contact number is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Send the form data to EmailJS
      emailjs
        .send(
          'service_08pma4t', // Replace with your EmailJS service ID
          'template_1i4iiee', // Replace with your EmailJS template ID
          formData, // The form data object
          '-syLCY-uCWCeuNq8h' // Replace with your EmailJS public key
        )
        .then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Message sent successfully!');
          },
          (error) => {
            console.log('FAILED...', error);
            alert('Failed to send the message. Please try again.');
          }
        );

      // Reset the form data and errors after submission
      setFormData({ name: '', email: '', phone: '', message: '' });
      setErrors({});
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl text-center font-semibold mb-8">
        Have a question or need a custom PCB solution? Reach out to our experts – we're here to help bring your projects to life!
      </h1>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="md:w-1/2">
          <form className="max-w-lg mx-auto p-4 border border-gray-300 rounded shadow-md bg-white" onSubmit={handleSubmit}>
            <h2 className="text-xl font-bold mb-4">Contact Us</h2>

            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Your Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your name"
              />
              {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Your Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
              />
              {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Contact Number</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your contact number"
              />
              {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Your Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your message"
                rows="4"
              ></textarea>
              {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
            </div>

            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200">
              Send Message
            </button>
          </form>
        </div>
        <div className="md:w-1/2 md:mt-0 mt-6">
        <iframe
  title="Google Maps Location" // Add a unique title here
  src="https://www.google.com/maps/embed?pb=..."
  width="80%"
  height="500"
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade">
</iframe>

        </div>
      </div>
    </div>
  );
};

export default ContactForm;
