import React from 'react';
import ExpertiseSection1 from '../../images/expertise.jpg';
import ExpertiseSection2 from '../../images/Belive In Success.webp';
import ExpertiseSection3 from '../../images/Employees.jpg';

export default function Expertise() {
  return (
    <div>
      <div className="flex flex-col md:flex-row items-center p-8 "
      >
        <div className="md:w-[60%] ml-16 mb-6 md:mb-0   "
        >
          <h1 className="text-4xl text-center font-bold text-[#150A76] mb-4">Expertise You Can Trust</h1>
          <p className="text-gray-700 text-left` text-lg leading-relaxed">
            No occupation is too huge or excessively little. We have the labor, hardware, and experience to change your item from a model to a regular creation run. The satisfaction of our clients with regards to the quality and cost efficiency for services is our supreme. We work at the capacity of 5000-8000 assemblies per day. We provide the featured rate quotations suiting the needs of respective clients. We are an ISO 9001:2015 CERTIFIED EMS COMPANY IN QMS (Quality Management Systems).No task is too big or too small for us. With the right workforce, advanced equipment, and extensive expertise, we can seamlessly transition your product from prototype to full-scale production. Ensuring our clients' satisfaction through top-quality service and cost-effective solutions is our utmost priority. We are equipped to handle 5000-8000 assemblies daily, offering competitive pricing tailored to meet the specific needs of each client. As an ISO 9001:2015 CERTIFIED EMS COMPANY, we strictly adhere to the highest standards in Quality Management Systems (QMS).
          </p>
        </div>
        <div className="md:w-[40%] flex justify-center "  >
          <img
            src={ExpertiseSection1}
            alt="Expertise"
            className="w-[60%] h-auto max-h-[400px] object-contain rounded-lg shadow-md "
          />
        </div>
      </div>
      {/* Belive In Success */}
      <div className="flex flex-col md:flex-row items-center p-8 bg-gray-50">
      <div className="md:w-[40%] flex justify-center">
          <img
            src={ExpertiseSection2}
            alt="Expertise"
            className="w-[60%] h-auto max-h-[400px] object-contain rounded-lg shadow-md"
          />
        </div>
        <div className="md:w-[60%] ml-16 mb-6 md:mb-0">
          <h1 className="text-4xl text-center font-bold text-[#150A76] mb-4">Success Through Excellence</h1>
          <p className="text-gray-700 text-center text-lg leading-relaxed">
          We are a rapidly growing Electronics Design and Manufacturing Services (EMS) company, experiencing significant expansion in both the domestic and export markets. Swastik Electrotech Automation operates as a non-IP holding company, meaning we do not produce proprietary products. Our expert and dynamic design team leverages advanced techniques and world-class processes, specializing in both SMD and Through-Hole technologies. This allows us to deliver top-quality, first-time-right products to our clients, ensuring precision and excellence in every project.     </p>
        </div>
      </div>
      {/* Employees */}
      <div className="flex flex-col md:flex-row items-center p-8 bg-gray-50">
        <div className="md:w-[60%] ml-16 mb-6 md:mb-0">
          <h1 className="text-4xl text-center font-bold text-[#150A76] mb-4">The People Behind Our Success</h1>
          <p className="text-gray-700 text-center text-lg leading-relaxed">
          Our belief in the power of great people is not just a saying—it’s the foundation of how we operate every day. We recognize that each employee plays a crucial role in our success. A strong company culture drives us to new heights. Our team is young, passionate, and equipped with expertise in every phase of Product Development, backed by years of collective experience in the Electronics Industry. We provide comprehensive end-to-end PCB assembly and box-build integration solutions for our valued clients. PCB assembly is our core strength, and we are equipped with cutting-edge technology and machinery to meet the evolving demands of our current and future customers.
          </p>
        </div>
        <div className="md:w-[40%] flex justify-center">
          <img
            src={ExpertiseSection3}
            alt="Expertise"
            className="w-[60%] h-auto max-h-[400px] object-contain rounded-lg shadow-md"
          />
        </div>
      </div>
    </div>
  );
}
