// WhatWeOffer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faShieldAlt, faHeadset, faCogs, faCheckCircle, faHandshake } from '@fortawesome/free-solid-svg-icons';

const WhatWeOffer = () => {
  return (
    <div className="py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto ml-12"> {/* Added ml-12 for left margin */}
        {/* Title */}
        <h2 className="text-4xl text-left font-bold text-[#150A76] mb-8 relative">
          What We Offer
          <span className="absolute left-0 -bottom-6 w-20 h-1 bg-[#150A76]" style={{ content: '""' }}></span>
        </h2>

        {/* Content */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12">
          {/* First Row */}
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <div className="mb-6">
              <FontAwesomeIcon icon={faTruck} className="text-4xl text-[#150A76] mb-4" />
            </div>
            <h3 className="text-xl font-semibold mb-4">On-Time Delivery</h3>
            <p className="text-gray-600">We ensure that all projects are delivered on time, without compromising quality.</p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <div className="mb-6">
              <FontAwesomeIcon icon={faShieldAlt} className="text-4xl text-[#150A76] mb-4" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Quality Assurance</h3>
            <p className="text-gray-600">We maintain the highest standards in quality assurance to provide the best products.</p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <div className="mb-6">
              <FontAwesomeIcon icon={faHeadset} className="text-4xl text-[#150A76] mb-4" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Good Service</h3>
            <p className="text-gray-600">Our team is always available to provide exceptional customer service and support.</p>
          </div>

          {/* Second Row */}
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <div className="mb-6">
              <FontAwesomeIcon icon={faCogs} className="text-4xl text-[#150A76] mb-4" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Innovative Solutions</h3>
            <p className="text-gray-600">We bring innovative solutions to solve complex challenges in your business.</p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <div className="mb-6">
              <FontAwesomeIcon icon={faCheckCircle} className="text-4xl text-[#150A76] mb-4" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Proven Results</h3>
            <p className="text-gray-600">Our results speak for themselves with a track record of success.</p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <div className="mb-6">
              <FontAwesomeIcon icon={faHandshake} className="text-4xl text-[#150A76] mb-4" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Long-Term Partnerships</h3>
            <p className="text-gray-600">We build long-term partnerships by focusing on mutual growth and success.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
