import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS

export default function HomeContact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false); // To show success message after submission

  // Handle changes in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Validate form data
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Contact number is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Set errors if validation fails
    } else {
      // Send email using EmailJS
      emailjs
        .send(
          'service_08pma4t', // Replace with your EmailJS Service ID
          'template_1i4iiee', // Replace with your EmailJS Template ID
          {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
          },
          '-syLCY-uCWCeuNq8h' // Replace with your EmailJS Public Key
        )
        .then(
          (response) => {
            console.log('Email sent successfully:', response.status, response.text);
            setSuccess(true); // Show success message
            setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form
            setErrors({});
          },
          (error) => {
            console.error('Failed to send email:', error);
          }
        );
    }
  };

  return (
    <div>
      <form className="max-w-lg mx-auto p-2 border border-gray-300 rounded shadow-md " onSubmit={handleSubmit}>
        <h2 className="text-xl font-bold mb-4">
          Have a question or need a custom PCB solution? Reach out to our experts – we're here to help bring your projects to life!
        </h2>

        {/* Name input */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Your Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your name"
          />
          {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
        </div>

        {/* Email input */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Your Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your email"
          />
          {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
        </div>

        {/* Phone input */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Contact Number</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your contact number"
          />
          {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
        </div>

        {/* Message textarea */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Your Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your message"
            rows="4"
          ></textarea>
          {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
        </div>

        {/* Submit button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
        >
          Send Message
        </button>

        {/* Success message */}
        {success && <p className="text-green-500 mt-4">Message sent successfully!</p>}
      </form>
    </div>
  );
}
