import React from 'react';
import videoSrc from '../../videos/Circuit-Board.mp4';

export default function HomeVideo() {
  return (
    <div className="relative h-screen overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white bg-black bg-opacity-50">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-center">
          Leading Electronic Manufacturing Services in India
        </h1>
        <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-center max-w-3xl sm:max-w-4xl lg:max-w-5xl">
          We specialize in manufacturing and assembling all types of PCBs as per client requirements...
        </h3>
      </div>
    </div>
  );
}
