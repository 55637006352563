import React, { useState } from 'react';


export default function StepStencileForm() {
    const [formData, setFormData] = useState({
        stencilName: '',
        stencilPrinter: '',
        stencilType: '',
        size: '',
        leadTime: '',
        quantity: '',
        topIP: '',
        bottomIP: '',
        bothIPThickness: '',
        specialRequirement: '',
        firstName: '',
        lastName: '',
        gerberFile: null
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-5xl">
                <h1 className="text-2xl font-bold mb-6">Framed Stencil Form</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Example of Horizontal Layout */}
                    {[
                        { label: 'Stencil Name', name: 'stencilName', type: 'text', required: true },
                        { label: 'Stencil Printer', name: 'stencilPrinter', type: 'text', required: true },
                        { label: 'Stencil Type', name: 'stencilType', type: 'text', required: true },
                        { label: 'Lead Time', name: 'leadTime', type: 'text', required: true },
                        { label: 'Quantity', name: 'quantity', type: 'number', required: true },
                    ].map((field, index) => (
                        <div className="flex items-center space-x-4" key={index}>
                            <label className="w-1/4 text-sm font-medium">{field.label}</label>
                            <input
                                type={field.type}
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleChange}
                                className="flex-grow p-2 border border-gray-300 rounded"
                                required={field.required}
                            />
                        </div>
                    ))}

                    {/* Select Fields */}
                    <div className="flex items-center space-x-4">
                        <label className="w-1/4 text-sm font-medium">Size</label>
                        <select
                            name="size"
                            value={formData.size}
                            onChange={handleChange}
                            className="flex-grow p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Size</option>
                            <option value="X">X</option>
                            <option value="Y">Y</option>
                        </select>
                    </div>

                    {/* File Upload */}
                    <div className="flex items-center space-x-4">
                        <label className="w-1/4 text-sm font-medium">Gerber File</label>
                        <input
                            type="file"
                            name="gerberFile"
                            onChange={handleChange}
                            className="flex-grow p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};


