import React from 'react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';  // Import Instagram icon
import { FaSquareFacebook } from 'react-icons/fa6';

export default function HeaderSocialIcon() {
  return (
    <div className="flex flex-col md:flex-row justify-evenly md:space-x-4 space-y-4 md:space-y-0 bg-[#150A76] p-2">
      {/* Contact Information */}
      <div className="text-white text-center md:text-left">
        <a href="mailto:swastikelectrotech.pune@gmail.com" className="hover:text-gray-200">
          swastikelectrotech.pune@gmail.com
        </a>
      </div>
      <div className="text-white text-center md:text-left">
        <a href="tel:+919112233188" className="hover:text-gray-200">
          9112233188 | 9112233177
        </a>
      </div>

      {/* Social Media Icons */}
      <div className="flex space-x-4 items-center justify-center md:justify-start">
        <a href="https://www.facebook.com/swastikelectrotechautomation" className="hover:text-white p-0.5 bg-white rounded-lg">
            <FaSquareFacebook size={25} className="text-[#3b5998]" />
        </a>
        <a href="https://www.linkedin.com/in/swastik-electrotech-automation-7b89b7256/" className="hover:text-white p-0.5 bg-white rounded-lg">
            <FaLinkedin size={25} className="text-[#0077b5]" />
        </a>
        <a href="https://www.instagram.com/swastikelectrotechautomation/" className="hover:text-white p-0.5 bg-white rounded-lg">
            <FaInstagram size={25} className="text-[#E4405F]" />
        </a>
      </div>
    </div>
  );
}
