import React from 'react'
import CareerHead from '../components/Career/CareerHead'
import CareerBody from '../components/Career/CareerBody'
export default function CareerPage() {
  return (
    <div>
      <CareerHead/>
      <CareerBody/>
    </div>
  )
}
