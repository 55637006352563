import React, { useState } from 'react';

export default function PCBAForm() {
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        subject: '',
        assemblyType: '',
        bomFile: null,
        topImage: null,
        bottomImage: null,
    });

    const [errors, setErrors] = useState({
        number: '',
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        
        // Number validation for exactly 10 digits
        if (name === 'number') {
            const numberRegex = /^[0-9]*$/; // Only digits allowed
            if (numberRegex.test(value) && value.length <= 10) {
                setFormData({ ...formData, [name]: value });
                setErrors({ ...errors, number: ' ' }); // Clear error if valid
            } else {
                setErrors({ ...errors, number: 'Please enter a valid 10-digit number.' });
            }
            return;
        }

        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0],  // For file inputs, save the first selected file
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if the phone number is exactly 10 digits before submitting
        if (formData.number.length !== 10) {
            setErrors({ ...errors, number: 'Number must be exactly 10 digits.' });
            return;
        }

        console.log(formData);
        // Add your form submission logic here (e.g., API call)
    };

    return (
        <div className="min-h-fit flex items-center justify-center bg-gray-100 p-8">
            <form onSubmit={handleSubmit} className="w-full max-w-6xl bg-white p-8 rounded-lg shadow-md">
                {/* Name Input */}
                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Name
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number">
                            Number
                        </label>
                        <input
                            id="number"
                            name="number"
                            type="tel"
                            placeholder="Enter your number"
                            value={formData.number}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                        {/* Display error message if invalid */}
                        {errors.number && <p className="text-red-500 text-xs mt-2">{errors.number}</p>}
                    </div>
                </div>

                {/* Email Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>

                {/* Subject Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                        Subject
                    </label>
                    <input
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="Enter subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>

                {/* Radio Input for Assembly Type */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Assembly Type</label>
                    <div className="flex gap-6">
                        <div className="flex items-center">
                            <input
                                id="smd"
                                name="assemblyType"
                                type="radio"
                                value="SMD"
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label htmlFor="smd" className="text-gray-700">SMD</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                id="through-hole"
                                name="assemblyType"
                                type="radio"
                                value="Through Hole"
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label htmlFor="through-hole" className="text-gray-700">Through Hole</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                id="both"
                                name="assemblyType"
                                type="radio"
                                value="Both"
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label htmlFor="both" className="text-gray-700">Both (SMD + Through Hole)</label>
                        </div>
                    </div>
                </div>

                {/* File Uploads */}
                <div className="mb-4 flex gap-4">
                    {/* BOM File */}
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bomFile">
                            Upload Bill of Materials (BOM)
                        </label>
                        <input
                            id="bomFile"
                            name="bomFile"
                            type="file"
                            accept=".csv,.xls,.xlsx"
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    {/* Top Image */}
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="topImage">
                            Upload Top Image of PCBA
                        </label>
                        <input
                            id="topImage"
                            name="topImage"
                            type="file"
                            accept="image/*"
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    {/* Bottom Image */}
                    <div className="flex-1">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bottomImage">
                            Upload Bottom Image of PCBA
                        </label>
                        <input
                            id="bottomImage"
                            name="bottomImage"
                            type="file"
                            accept="image/*"
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}
