import React from 'react'
import './Comphead.css'
const Comphead = () => {
  return (
    <div className='comphead-1'>
        <h1>Our Milestones</h1>
    </div>
  )
}

export default Comphead