import React from 'react';
import './Component.css';
import image1 from '../../images/pcblayeranimation.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faCalendarAlt, faProjectDiagram, faAward } from '@fortawesome/free-solid-svg-icons';

const Component = () => {
  return (
    <div className='comp-main'>
      <div className='main-cont-comp'>
        <div className='comp1-left'>
          <img src={image1} alt='' />
        </div>
        <div className='comp1-right'>
          <div className='comp1-right-cont'>
            <div className='comp1-item'>
              <FontAwesomeIcon icon={faSmile} className="icon-style" />
              <p><strong className='strongus'>1000+</strong> Happy Clients we have served over the period.</p>
            </div>
            <div className='comp1-item'>
              <FontAwesomeIcon icon={faCalendarAlt} className="icon-style" />
              <p><strong className='strongus'>20+</strong> Years of experience in the field of Designing and Manufacturing.</p>
            </div>
            <div className='comp1-item'>
              <FontAwesomeIcon icon={faProjectDiagram} className="icon-style" />
              <p><strong className='strongus'>1000+</strong> Projects Designs served in recent years.</p>
            </div>
            <div className='comp1-item'>
              <FontAwesomeIcon icon={faAward} className="icon-style" />
              <p><strong className='strongus'>50+</strong> Industry awards in Industrial Manufacturing.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
