import React from 'react';
import background from '../../images/pcb-bg.png';
import careerImg from '../../images/career_image.jpg';

export default function CareerHead() {
  return (
    <div>
        <div className="relative w-full h-80 overflow-hidden">
            <img 
                src={background} 
                alt="PCB Background" 
                className="w-full h-full object-cover" 
            />
            <h1 className="absolute inset-0 flex items-center justify-center text-4xl font-bold text-white bg-black bg-opacity-50 text-center">
                Empower Your Potential, Build Your Career
            </h1>
        </div>
        
        {/* Added Tailwind CSS to careerImg */}
        <div className="flex justify-center mt-8">
            <img 
                src={careerImg} 
                alt="Career"
                className="w-2/3 object-contain  border-4 border-white shadow-lg" 
            />
        </div>
    </div>
  );
}
