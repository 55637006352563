import React from 'react';
import HomeVideo from '../components/frontPage/HomeVideo';
import CompanyProfile from '../components/frontPage/CompanyProfile';
import Counter from '../components/frontPage/Counter';
import MarketServed from '../components/frontPage/MarketServed';
import Expertise from '../components/frontPage/Exprtise';
import RelationsAdvice from '../components/frontPage/RelationsAdvice';
import HomeContact from '../components/frontPage/HomeContact';

import backgroundImg from '../images/pcb1.png'


export default function Home() {
  return (
    <>
      <section id="home-video">
        <HomeVideo />
      </section>



<section 
  id="company-profile" 
  className="w-full h-full bg-cover bg-center bg-no-repeat bg-opacity-50"
  // style={{ backgroundImage: `url(${backgroundImg})` }}
>
  <div className="flex w-full">
    <div className="w-3/5">
      <CompanyProfile />
    </div>
    <div className="w-2/5 p-2">
      <HomeContact />
    </div>
  </div>
</section>



      <section id="counter">
        <Counter />
      </section>

      <section id="expertise">
        <Expertise />
      </section>

      <section id="market-served">
        <MarketServed />
      </section>

      <section id="relations-advice">
        <RelationsAdvice />
      </section>
    </>
  );
}
