import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.jpg';
import { IoMdArrowDropdown } from "react-icons/io";

const Header = () => {
  const [openDropdown, setDropdown] = useState('');
  const [openMenu, setOpenMenu] = useState(false); // For mobile menu toggle
  const dropdownRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setDropdown('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdown) => {
    setDropdown((prev) => (prev === dropdown ? '' : dropdown));
  };

  const handleMenuItemClick = () => {
    setDropdown('');
    setOpenMenu(false); // Close the mobile menu when a menu item is clicked
  };

  return (
    <header className="py-2">
      <div className="container mx-auto flex items-center justify-between px-4">
        {/* Logo */}
        <div>
          <Link to="/" className="flex items-center justify-start">
            <img src={Logo} alt="Logo" className="h-16" />
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button onClick={() => setOpenMenu(!openMenu)} className="text-2xl">
            {openMenu ? 'X' : '☰'} {/* Hamburger menu icon */}
          </button>
        </div>

        {/* Navigation (Desktop & Mobile) */}
        <nav
          className={`font-bold md:flex justify-center space-x-10 md:space-x-5 md:static absolute md:bg-transparent bg-blue-100 md:w-auto w-full left-0 top-0 transition-all duration-300 ease-in-out ${openMenu ? 'top-12' : '-top-full'}`}
          ref={navRef}
        >
          {/* Mobile Navigation: flex-col for vertical stacking */}
          <div className={`md:flex md:flex-row md:space-x-10 w-full ${openMenu ? 'flex-col' : 'hidden'} md:block`}>
            <Link to="/" className="hover:text-[#3B5998] py-2 md:px-0 block md:inline text-center">Home</Link>
            <Link to="/about" className="hover:text-[#3B5998] py-2 md:px-0 block md:inline text-center">About Us</Link>

            {/* Solutions Dropdown */}
            <div className="relative">
              <Link
                to="#solutions"
                className="hover:text-[#3B5998] flex items-center py-2 md:px-0"
                onClick={() => handleDropdownToggle('solutions')}
              >
                Solutions
                <IoMdArrowDropdown className="inline ml-1" />
              </Link>
              <div
                className={`absolute left-0 mt-2 w-48 bg-white border border-gray-50 shadow-lg z-dropdown transform transition-all duration-300 ease-in-out ${openDropdown === 'solutions' ? 'scale-100 opacity-100' : 'scale-95 opacity-0 pointer-events-none'}`}
              >
                <Link
                  to="/ems"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  Electronic Manufacturing Services
                </Link>
                <Link
                  to="/pcb"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  Printed Circuit Board Assembling
                </Link>
                <Link
                  to="/prototypePCB"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  Prototype PCB Manufacturing & Assembling
                </Link>
                <Link
                  to="/StencilePCB"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  Stencil PCB
                </Link>
              </div>
            </div>

            {/* Services Dropdown */}
            <div className="relative">
              <Link
                to="#services"
                className="hover:text-[#3B5998] flex items-center py-2 md:px-0"
                onClick={() => handleDropdownToggle('services')}
              >
                Services
                <IoMdArrowDropdown className="inline ml-1" />
              </Link>
              <div
                className={`absolute left-0 mt-2 w-48 bg-white border border-gray-50 shadow-lg z-dropdown transform transition-all duration-300 ease-in-out ${openDropdown === 'services' ? 'scale-100 opacity-100' : 'scale-95 opacity-0 pointer-events-none'}`}
              >
                <Link
                  to="/smd"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  SMD
                </Link>
                <Link
                  to="/smdwpc"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  SMD Word Printed Circuit Board Assembly
                </Link>
                <Link
                  to="/pcbaAssebJob"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  PCB Assembly job Work
                </Link>
                <Link
                  to="/PCBAutomatctAssemblePage"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  PCB Automatic Assembly
                </Link>
                <Link
                  to="/ledLight"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  LED Light Circuit Board Assembly
                </Link>
              </div>
            </div>

            {/* Gallery Dropdown */}
            <div className="relative">
              <Link
                to="#gallery"
                className="hover:text-[#3B5998] flex items-center py-2 md:px-0"
                onClick={() => handleDropdownToggle('gallery')}
              >
                Gallery
                <IoMdArrowDropdown className="inline ml-1" />
              </Link>
              <div
                className={`absolute left-0 mt-2 w-48 bg-white border border-gray-50 shadow-lg z-dropdown transform transition-all duration-300 ease-in-out ${openDropdown === 'gallery' ? 'scale-100 opacity-100' : 'scale-95 opacity-0 pointer-events-none'}`}
              >
                <Link
                  to="/gallery"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  Photo
                </Link>
                {/* <Link
                  to="#gallery2"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleMenuItemClick}
                >
                  Video
                </Link> */}
              </div>
            </div>
            <Link to="/CareerPage" className="hover:text-[#3B5998] py-2 md:px-0 block md:inline text-center">Career</Link>
            <Link to="/team" className="hover:text-[#3B5998] py-2 md:px-0 block md:inline text-center">Team</Link>
            <Link to="/contact" className="hover:text-[#3B5998] py-2 md:px-0 block md:inline text-center">Locate Us</Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
