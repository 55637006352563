import React from 'react';

export default function CompanyProfile() {
    return (
        <div className=" w-auto mx-auto p-6 px-20 text-left bg-blue-100">
            {/* <h1 className="text-4xl font-bold mb-4 text-[#150A76] text-left">Company At Glance</h1> */}
            <h2 className="text-4xl text-left font-bold text-[#150A76] mb-8 relative">
            Company At Glance
          <span className="absolute left-0 -bottom-3 w-20 h-1 bg-[#150A76]" style={{ content: '""' }}></span>
        </h2>
            <p className="text-lg mb-4">
                The Company was Established in 2013, Contractual electronic Manufacturing Services company located in Pune. Assemble all kinds of PCB’s as per vendor’s requirement. Experienced employees with well-equipped and upgraded machinery. Our specialties lie in “Quality Assembling Processing” and “Speedy Delivery”. The company was incorporated with the dreams of serving excellence with perfection.
            </p>
            <p className="text-lg mb-4">
                We are basically engaged in manufacturing, supplying, and trading of PCB circuits and Leading Electrical Automation Solutions. We export our products to the Indian subcontinent at affordable prices. Our entire product and service collection includes PCB Design, PCB Assembling, PCB Board, Printed Circuit Board, PCB Reverse, and many more.
            </p>
            <p className="text-lg">
                The company has gained strong clients with long-term relationships of services. We are interested in dealing with all those companies who are looking for long-term business. We are focusing on availing bulk quantity output in the minimum possible duration. While you’re presenting another item, you want to rapidly move. Also, you can’t stand to compromise — on the grounds that sitting around idly and driving up expenses to fix mistakes or defects can mean losing the opportunity you were attempting to seize. You’ll get a team that is dedicated to reducing your time to market.
            </p>
        </div>
    );
}
