import React from 'react'
import StencileHead from '../components/Solution/StencilPCB/StencilHeader';
import StencileProcedure from '../components/Solution/StencilPCB/StencilProcedurePage'
// import FramedForm from '../components/Solution/StencilPCB/FramedStencilForm'
// import FramelessForm from '../components/Solution/StencilPCB/FramelessStencilForm'

export default function StencilePCBpage() {
  return (
    <div>
      <StencileHead/>
      <StencileProcedure/>
      {/* <FramedForm/> */}
      {/* <FramelessForm/> */}
    </div>
  )
}
