// pages/AboutUs.js
import React from 'react';
import WhoWe from '../components/aboutUs/WhoWe';
import Components from '../components/aboutUs/Components'
import Compheads from '../components/aboutUs/Compheads'
import ServiceConsulting from '../components/aboutUs/ServiceForConsulting';
import Facility from '../components/aboutUs/Facility'; 
import Clients from '../components/aboutUs/Clients';
import Aboutheads from '../components/aboutUs/AboutHeads.jsx';
import WhatWeOffer from '../components/aboutUs/Whatweoffer';

// import WhoWe from '../components/aboutUs/WhoWe';
// import WhyChoose from '../components/aboutUs/WhyChoose';
// import ServiceConsulting from '../components/aboutUs/ServiceForConsulting';
// import Facility from '../components/aboutUs/Facility'; 
// import Clients from '../components/aboutUs/Clients';

export default function AboutUs() {
  return (
    <div>
      {/* <section id="who-we">
        <WhoWe />
      </section>
      <section id="why-choose">
        <WhyChoose />
      </section>
      <section id="service-consulting">
        <ServiceConsulting />
      </section>
      <section id="facility">
        <Facility />
      </section>
      <section id="clients">
        <Clients />
      </section> */}

      <Aboutheads/>
      <WhoWe/>
      <Compheads/>
      <Components/>
      <WhatWeOffer/>
      <ServiceConsulting/>
      <Facility/>
      <Clients/>

    </div>
  );
}
