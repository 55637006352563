import React, { useState } from 'react';
import FramedStencilForm from './FramedStencilForm'; // Form for Framed Stencils
import FramelessStencilForm from './FramelessStencilForm'; // Form for Frameless Stencils
import StepStencileForm from './StepStencileForm';
import EMSimg from '../../../images/Solution/WMS.png';

const StencilProcedurePage = () => {
    const [selectedForm, setSelectedForm] = useState(null); // State to track the selected form

    const handleButtonClick = (formType) => {
        setSelectedForm(formType); // Update state with the selected form type
    };

    const closeForm = () => {
        setSelectedForm(null); // Reset state to hide the form
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-8">
            {selectedForm ? (
                <div className="relative w-full max-w-5xl">
                    <button
                        onClick={closeForm}
                        className="absolute top-2 right-2 bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    >
                        Close
                    </button>
                    {/* Conditionally render forms based on selectedForm */}
                    {selectedForm === 'Framed' && <FramedStencilForm />}
                    {selectedForm === 'Frameless' && <FramelessStencilForm />}
                    {selectedForm === 'Step' && <div><StepStencileForm/></div>}
                </div>
            ) : (
                <div className="flex w-full max-w-full bg-white rounded-lg shadow-md p-6">
                    <div className="w-3/5 pr-4">
                        <h1 className="text-2xl font-bold text-gray-800 mb-4">
                            Stencil Procedure in Visual Art
                        </h1>
                        <p className="text-gray-700 mb-4">
                            The stencil technique, often referred to as <em>pochoir</em>, involves creating images or designs by applying paint or ink over a pre-cut template. This method allows for precision and consistency in reproducing patterns across various surfaces.
                        </p>
                        <h2 className="text-xl font-semibold text-gray-800 mb-2">Types of Stencils:</h2>
                        <ul className="list-disc list-inside mb-4 text-gray-700">
                            <li><strong>PCB Stencil Quotes:</strong> Tailored for PCB manufacturing.</li>
                            <li><strong>Framed Stencils:</strong> Easy to handle and use.</li>
                            <li><strong>Step Stencils:</strong> Designed for multi-layered applications.</li>
                        </ul>
                        <div className="flex justify-between mt-8">
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                onClick={() => handleButtonClick('Framed')}
                            >
                                Framed Stencils
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                onClick={() => handleButtonClick('Frameless')}
                            >
                                Frameless Stencils
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                onClick={() => handleButtonClick('Step')}
                            >
                                Step Stencils
                            </button>
                        </div>
                    </div>
                    <div className="w-2/5 flex justify-center">
                        <img
                            src={EMSimg}
                            alt="EMS Service"
                            className="max-w-full h-auto rounded shadow-md"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default StencilProcedurePage;
