import { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import Logo from '../images/logo.jpg';
import { IoMdArrowDropdown } from "react-icons/io";

export default function Footer() {
  const [openDropdown, setDropdown] = useState('');

  const handleDropdownToggle = (dropdown) => {
    setDropdown(openDropdown === dropdown ? '' : dropdown);
  };

  return (
    <footer className="bg-gray-800 text-white py-3">
      <div className="container mx-auto px-4">
        {/* Call to Action Section */}
        <div className="flex flex-col items-center mb-3">
          <h4 className="text-2xl font-semibold mb-2 text-center">Want to grow your business? Connect with us here</h4>
          {/* <Link to="/contact">
            <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">Contact Us</button>
          </Link> */}
        </div>
        <hr className="border-gray-700 mb-6" />

        {/* Footer Content */}
        <div className="flex flex-wrap justify-between gap-4 mb-8">
          {/* Company Info */}
          <div className="flex-1 min-w-[200px] px-4 mb-4 md:mb-0">
            <img src={Logo} alt="Company Logo" className="h-16 mb-4" />
            <p className="text-gray-400">
              Established in 2013, our company is a contractual electronics manufacturing and services provider based in Pune. We specialize in assembling all types of PCBs according to vendors' requirements. Our team of experienced professionals works with state-of-the-art, well-equipped, and up-to-date machinery to deliver high-quality results.
            </p>
          </div>

          {/* Quick Links */}
          <div className="flex-1 pl-28 min-w-[200px] px-4 mb-4 md:mb-0 text-gray-300">
            <h2 className="text-xl font-bold mb-2">Quick Links</h2>
            <nav className=" space-y-2">
              <div>
                <Link to="/" className="hover:text-blue-400">Home</Link>
              </div>
              <div>
                <Link to="/about" className="hover:text-blue-400">About Us</Link>
              </div>
              {/* Solutions Dropdown */}
             {/* Solutions Dropdown */}
<div className="relative">
  <span
    className="flex items-center cursor-pointer hover:text-blue-400"
    onClick={() => handleDropdownToggle('solutions')}
  >
    Solutions
    <IoMdArrowDropdown className="ml-1" />
  </span>
  {openDropdown === 'solutions' && (
    <div className="absolute left-0 mt-2 w-48 bg-white text-gray-800 border border-gray-200 shadow-lg z-10 max-h-60 overflow-y-auto">
      <Link to="/ems" className="block px-4 py-2 hover:bg-gray-100">Electronic Manufacturing Services</Link>
      <Link to="/pcb" className="block px-4 py-2 hover:bg-gray-100">Printed Circuit Board Assembling</Link>
      <Link to="/prototypePCB" className="block px-4 py-2 hover:bg-gray-100">Prototype PCB Manufacturing & Assembling</Link>
      <Link to="/StencilePCB" className="block px-4 py-2 hover:bg-gray-100">Stencil PCB</Link>
    </div>
  )}
</div>

              {/* Services Dropdown */}
              <div className="relative">
                <span
                  className="flex items-center cursor-pointer hover:text-blue-400"
                  onClick={() => handleDropdownToggle('services')}
                >
                  Services
                  <IoMdArrowDropdown className="ml-1" />
                </span>
                {openDropdown === 'services' && (
                     <div className="absolute left-0 mt-2 w-48 bg-white text-gray-800 border border-gray-200 shadow-lg z-10 max-h-40 overflow-y-auto">

                    <Link to="/smd" className="block px-4 py-2 hover:bg-gray-100">SMD</Link>
                    <Link to="/smdwpc" className="block px-4 py-2 hover:bg-gray-100">SMD Board Printed Circuit Assembly</Link>
                    <Link to="/pcbaAssebJob" className="block px-4 py-2 hover:bg-gray-100">PCB Assembly Job Work</Link>
                    <Link to="/PCBAutomatctAssemblePage" className="block px-4 py-2 hover:bg-gray-100">PCB Automatic Assembly</Link>
                    <Link to="/ledLight" className="block px-4 py-2 hover:bg-gray-100">LED Light Circuit Board Assembly</Link>
                  </div>
                )}
              </div>

              {/* Gallery Dropdown */}
              <div className="relative">
                <span
                  className="flex items-center cursor-pointer hover:text-blue-400"
                  onClick={() => handleDropdownToggle('gallery')}
                >
                  Gallery
                  <IoMdArrowDropdown className="ml-1" />
                </span>
                {openDropdown === 'gallery' && (
                  <div className="absolute left-0 mt-2 w-48 bg-white text-gray-800 border border-gray-200 shadow-lg z-10">
                    <Link to="/gallery1" className="block px-4 py-2 hover:bg-gray-100">Photo</Link>
                    <Link to="/gallery2" className="block px-4 py-2 hover:bg-gray-100">Video</Link>
                  </div>
                )}
              </div>

              <div>
                <Link to="/team" className="hover:text-blue-400">Team</Link>
              </div>
              <div>
                <Link to="/career" className="hover:text-blue-400">Career</Link>
              </div>
              <div>
                <Link to="/locate" className="hover:text-blue-400">Locate Us</Link>
              </div>
            </nav>
          </div>

          {/* Locate Us */}
          <div className="flex-1 min-w-[200px] px-4 mb-4 md:mb-0 text-gray-300">
            <h2 className="text-xl font-bold  mb-4">Locate Us</h2>
            <h5 className="text-md font-semibold mb-2">SWASTIK ELECTROTECH AUTOMATION</h5>
            <hr className="my-2 border-gray-600" />
            <p className="mb-2">SURVEY NO. 36 1/2/4 Narhe Industrial Area, Near Canara Bank, NARHE, PUNE -411041, MAHARASHTRA, INDIA.</p>
            <p className="mb-2">GSTIN: 27ABNPI2487BIZN</p>
            <hr className="my-2 border-gray-600" />
            <p className="mb-2">
              <span className="mr-2">📞</span>
              <a href="tel:+919112233188" className="hover:text-blue-400">9112233188 | 9112233177</a>
            </p>
            <p>
              <span className="mr-2">📧</span>
              <a href="mailto:swastikelectrotech.pune@gmail.com" className="hover:text-blue-400">swastikelectrotech.pune@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-700 text-center py-4">
        <p className="text-gray-300 text-sm">
          Copyright © 2023 Swastik Electrotech Automation | Made with ❤️ by Cortica Web Solutions Pvt. Ltd.
        </p>
      </div>
    </footer>
  );
}
