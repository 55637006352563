import React from 'react'
import client1 from '../../images/clients/gizmo.png';
import client2 from '../../images/clients/havells.png';
import client3 from '../../images/clients/hella.jpeg';
import client4 from '../../images/clients/firstcry.jpeg';
import client5 from '../../images/clients/uno.png';
import client6 from '../../images/clients/dharma.jpeg';

export default function Clients() {
  return (
    <div className="container mx-auto px-4">
    <h1 className="text-4xl font-bold text-left text-[#150A76] mb-12 relative inline-block">
  Our Clients
  <span className="absolute left-0 -bottom-6 w-20 h-1 bg-[#150A76]" style={{ content: '""' }}></span>
</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
        <div className="p-4">
          <img className="w-full h-40 object-contain" src={client1} alt="client1" />
        </div>
        <div className="p-4">
          <img className="w-full h-40 object-contain" src={client2} alt="client2" />
        </div>
        <div className="p-4">
          <img className="w-full h-40 object-contain" src={client3} alt="client3" />
        </div>
        <div className="p-4">
          <img className="w-full h-40 object-contain" src={client4} alt="client4" />
        </div>
        <div className="p-4">
          <img className="w-full h-40 object-contain" src={client5} alt="client5" />
        </div>
        <div className="p-4">
          <img className="w-full h-40 object-contain" src={client6} alt="client6" />
        </div>
      </div>
    </div>
  )
}
